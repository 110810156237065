/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 19, 2016, 12:41:31 PM
    Author     : william
*/

.tab-content-custom-1{
    border: 1px solid @nav-tabs-active-link-hover-border-color;
    border-top-color: transparent;
    border-radius: 0px 0px 4px 4px;
}