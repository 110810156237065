 /*==========  Mobile First Method  ==========*/
	.main-nav{
		height: @main-nav-height;
	}	
    /* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
		.logo{
			height:100%;
		}
		.navbar-nav{
			width:100%;
		}
		.navbar-text{
			min-height: @main-nav-height;
		}
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
		.logo{
			height:100%;
		}
		.navbar-nav{
			width:100%;
		}
		.navbar-text{
			min-height: @main-nav-height;
		}
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
		.logo{
			height:100%;
		}
		.navbar-nav{
			width:100%;
		}
		.navbar-text{
			min-height: @main-nav-height;
		}
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
		.logo{
			height:100%;
		}
		.navbar-nav{
			width:100%;
		}
		.navbar-text{
			min-height: @main-nav-height;
		}
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
		.logo{
			height:100%;
		}
		.navbar-nav{
			width:100%;
		}
		.navbar-text{
			min-height: @main-nav-height;
		}
    }
