
    .text-padding{
            padding: 5px;
    }
    .nav-tab-custom-1 a{
        color: @nav-tabs-active-link-hover-color;
        border: 1px solid @nav-tabs-active-link-hover-border-color;
        border-bottom-color: transparent;
        cursor: pointer;
        outline: 0;
    }
    .nav-tab-custom-1 li.active{
        
    }