/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Nov 4, 2015, 12:05:25 PM
    Author     : william wong
*/
.no-border{
    border-width:0px;
}
.border-custom-1{
    border:3px solid #DFEEF2;
    border-radius: 5px;
}
