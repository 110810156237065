
/* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
        
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
		.small-thumbnails img{
			height: 150px;
		}
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
		.small-thumbnails img{
			height: 150px;
		}
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
		.small-thumbnails img{
			height: 175px;
		}
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
		.small-thumbnails img{
			height: 175px;
		}
    }