/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Nov 23, 2015, 12:06:46 PM
    Author     : william
*/

.top-on-scroll{
    position:relative;
    top:0px;
}