/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Oct 23, 2015, 9:48:43 AM
    Author     : william
*/
.flex{

}
.flex > div{
    display:flex;
    text-align:center;
    justify-content: center;
}