.extra-horizontal-margin{
	margin: 10px 0px;
}
.no-margin{
    margin:0px;
}



@media only screen and (min-width : 320px) {
    .header-fill{
        height:130px;
    }
}


@media only screen and (min-width : 480px) {
    .header-fill{
        height:130px;
    }
}


@media only screen and (min-width : 768px) {
    .header-fill{
        height:90px;
    }
}
