/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Nov 9, 2015, 2:22:43 PM
    Author     : william
*/

.label-format{
    width:30%;
    text-align:right;
    vertical-align:middle;
}
