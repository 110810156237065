footer{
	margin-top: 10px;
	.light-black-bg{
		padding: 10px 0px;
	}
	.list-group-item{
		background: #333;
		color: #CCC;
	}
	.list-group-item.light-grey{
		background: #666 !important;
		color: #FFF;
	}
}
footer > div{
    border-top:5px solid #666;
}
footer.fixed{
    width:100%;
    position:fixed;
    bottom:0px;
}