/* Detail Box */
.box-detail {
	background: #FFF;
	border:3px solid #DFEEF2;
	padding:0px;
	text-align:center;
}
.detail-heading{
	text-align:center
}
.detail-content div{
    display:flex;
    text-align:center;
    justify-content: center;
}
.detail-content .center{
    align-items: center;
    
}
.detail-terms{
	background: #09F;
	padding: 2px;
	color:#FFF;
	text-align:center;
	}
.detail-terms small{
	color:#FFF;
}
.detail-action{
	padding:10px;
}
.box-detail ul{
	list-style-type:none;
	padding:0px 0px 0px 0px;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	.box-detail {
		//min-height: 225px;
	}
	.detail-heading{
		text-align:center;
		min-height:50px;
		padding:10px 0px 0px 0px;
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	.box-detail {
		//min-height: 200px;
	}
	.detail-heading{
		text-align:center;
		min-height:50px;
		padding:10px 0px 0px 0px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	.box-detail {
		//min-height: 200px;
	}
	.detail-heading{
		text-align:center;
		min-height:50px;
		padding:10px 0px 0px 0px;
	}
}

/*  Price Box */
.box-price  {
	background: #FFF;
	border:3px solid #DFEEF2;
	padding:0px;
	text-align:center;
}
.pricing-heading{
	text-align:center;
        padding: 10px 0px;
}
.box-price h2, .box-price h3, .box-prices h4{
	margin:0px;
}
.pricing-terms{
	background: #26abe2;
	padding: 2px;
	color:#FFF;
	text-align:center;
	}
.pricing-terms small{
	color:#FFF;
}
.pricing-action {
    text-align:center;
}





/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .pricing-content-1{
            //padding:5px 10px;
    }
    .pricing-content-2, pricing-content-3{
            //padding:10px;
    }
        
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .pricing-content-1{
            //padding:5px 10px;
    }
    .pricing-content-2, pricing-content-3{
            //padding:10px;
    }

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .pricing-heading{
            text-align:center;
            //min-height:60px;
            //padding:10px 0px 0px 0px;
    }
    .pricing-content-1{
            min-height:50px;
            //padding:10px;
    }
    .pricing-content-1{
            height:20px;
            //padding:10px;
    }
    .pricing-content-2{
            min-height:40px;
            //padding:10px;
    }
    .pricing-content-2{
            //height:40px;
            //padding:10px;
    }
    .pricing-content-3{
        height:80px;
        //padding:10px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .pricing-heading{
        text-align:center;
            //min-height:50px;
           // padding:10px 0px 0px 0px;
    }
    .pricing-content-1{
        min-height:40px;
        //padding:10px;
    }
    .pricing-content-1{
            height:20px;
            padding:10px;
    }
    .pricing-content-2{
            min-height:50px;
            //padding:10px;
    }
    .pricing-content-2{
            //height:40px;
            //padding:10px;
    }
    .pricing-content-3{
            height:80px;
            //padding:10px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .pricing-heading{
            text-align:center;
            //min-height:50px;
            //padding:10px 0px 0px 0px;
    }
    .pricing-content-1{
        min-height:40px;
        //padding:10px;
        }
        .pricing-content-1{
                height:20px;
                //padding:10px;
        }
    .pricing-content-2{
        min-height:60px;
            //padding:10px;
    }
    .pricing-content-2{
        height:60px;
           // padding:10px;
    }
    .pricing-content-3{
            height:80px;
            //padding:10px;
    }
}

.box-spacing-1{
    padding:10px;
}