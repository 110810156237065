/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 15, 2016, 2:40:25 PM
    Author     : william
*/

.fixed{
    position:fixed;
    width:100%;
    z-index:2;
}
.fixed > div{
    position:relative;
}