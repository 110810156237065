/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Dec 18, 2015, 3:00:34 PM
    Author     : william
*/

.list-checkmark ul {
    list-style-image: url('images/Icon_Checkblue.gif');
}