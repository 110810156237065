.fit-width{
	img{
		width:100%;
	}
}
.half-width{
	img{
		width:50%;
	}
}
.quarter-width{
	img{
		width:25%;
	}
}
.small-icon img{
	height:30px;
	width:30px;
}
.small-thumbnail img{
	height:75px;
	width:75px;
}
.description_small_icon{
    margin: 10px 0px;
}
.description_small_icon img{
    height:40px;
    width:40px;
}