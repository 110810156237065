.black-bg{
	color: #FFFFFF;
	background:black;
}

// Nav Tabs
.black-bg .nav-tabs a{
	color: #FFFFFF;
}
.black-bg .nav-tabs a:hover{
	color: #000000;
}
.light-black-bg{
	background: #333;
	color: #CCC;
}
.sky_bg{
	background-image: url("../../images/mainbg.jpg");
	background-repeat: repeat-x;
}
.light-grey{
	color: #FFFFFF;
	background:#666;
}
.white-bg{
    background:#FFFFFF;
}