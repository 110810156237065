/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 18, 2016, 10:39:47 AM
    Author     : william
*/

sup {
  top: -0.25em;
}