.carousel {
	.item img{
		width: 100%;
		
	}
	.transparent{
		background:transparent;
	}
	.custom-indicators li {
	  background-color: #CCC;
	}

	.custom-indicators .active {
	  background-color: #000;
	}
	.custom-indicators{
		bottom:0px;
	}
}

/*==========  Mobile First Method  ==========*/

    /* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
        .carousel {
			.item{
			
			}
		}	
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
		
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
		.carousel {
		}
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {
		.carousel {
			.item{
			}
		}
		
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {
		.carousel {
			.item{
			}
		}
    }
