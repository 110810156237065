/* Custom, iPhone Retina */ 
.sub_menu{
	height: 50px;
}
.sub_menu a:hover{
    background-image: linear-gradient(to bottom, #5BC0DE 0px, #2AABD2 100%);
    color:#fff;
}
@media only screen and (min-width : 320px) {
.sub_menu_font{
	font-size: 13px;
}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .sub_menu_font{
            font-size: 13px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .sub_menu_font{
            font-size: 13px;
    }
    .sub_menu{
	height: 50px;
        margin-top:10px;
	a, .text-padding{
		padding:6px 6px !important;
	}
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .sub_menu_font{
            font-size: 18px;
    }
    .sub_menu{
        margin-top:5px;
    }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

