/* Detail Box */
.table{
    cursor: pointer;
    width:100%;
}
.table td{
    word-break:break-word;
}
.table.table-no-margin{
    margin:0px;
}
.table.table-top-less tr:first-of-type td{
    border-top: none !important;
}
.table.table-right-side td{
    border-right: 1px solid @table-border-color;
}
.table.table-right-side tr td:last-child{
    border-right-width: 0px;
}
.table .no-padding{
    padding:0px;
}
.table_lift tr{
        vertical-align:top;
}
.table.no-border, .table.no-border tr, .table.no-border td, .table.no-border th{
    border-width:0px;
}
.form-table{
    td{
        padding: 0px 8px;
        vertical-align:middle;
    }
}