/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Nov 12, 2015, 11:05:50 AM
    Author     : william
*/

label{
    display:block;
    margin-bottom:0px;
}