/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Dec 2, 2015, 12:24:33 PM
    Author     : william
*/
.alert{
    margin-bottom:0px;
}

